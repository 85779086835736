<template>
  <div class="ocular" v-if="showConent">
    <p class="title">屈光视力检测结果查询</p>
    <van-form @submit="onSubmit" ref="formData">
      <van-field
        v-model="name"
        name="姓名"
        label="姓名"
        placeholder="请输入学生姓名"
      />
      <van-field
        readonly
        clickable
        v-model="birthDay"
        name="出生日期"
        label="出生日期"
        placeholder="请选择证件上的出生日期"
        @click="showPopFn()"
      />
      <div class="bottomBtn">
        <p @click="submit" class="activeBtn2" v-if="name && birthDay">
          查询
        </p>
        <p class="inActiveBtn" @click="submit" v-else >
          查询
        </p>
      </div>
    </van-form>
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        type="date"
        title="请选择证件上的出生日期"
        :minDate="minDate"
        :maxDate="maxDate"
        v-model="currentDate"
        :show-toolbar="true"
        @confirm="datePickerOnConfirm"
        @cancel="datePickerOnCancel"
        :formatter="formatter"
      ></van-datetime-picker>
    </van-popup>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import getcollect from '../../../utils/databurying'
export default {
  data() {
    return {
      name: '',
      birthDay: '',
      currentpagepath: '',
      comfrompath: '',
      showConent: false,
      showDatePicker: false,
      minDate: new Date(1920, 1, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate:new Date(2000, 0, 1)
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from, '页面')
    next((vm) => {
      vm.currentpagepath = to.fullPath
      vm.comfrompath = from.fullPath
    })
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      }
      if (type === 'month') {
        return `${val}月`
      }
      if (type === 'day') {
        return `${val}日`
      }
      return val
    },
    timeFormat(time) {
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let day = time.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    },
    showPopFn() {
      this.showDatePicker = true
    },
    datePickerOnConfirm(e) {
      console.log(`${JSON.stringify(e)}`)
      this.showDatePicker = false
      this.birthDay = JSON.parse(JSON.stringify(this.timeFormat(e)))
    },
    datePickerOnCancel(e) {
      this.showDatePicker = false
    },
    submit() {
      this.$refs.formData.submit()
    },
    onSubmit(values) {
      if(this.name == '' || this.name == undefined || this.name == null){
         this.$toast("请输入学生姓名");
         return 
      }
      if(this.birthDay == '' || this.birthDay == undefined || this.birthDay == null){
         this.$toast("请选择证件上的出生日期");
         return 
      }
      console.log('submit', values)
      this.$router.push({
        path: '/temperatureAllowance/ocularOpticalTestingList',
        query: {
          name: this.name,
          birthDay: this.birthDay,
        },
      })
    },
    //数据埋点
    openweixin() {
      let gid = uuidv4().replace(/-/g, '')
      let p = this.currentpagepath
      let t = 'open'
      let r = uuidv4().replace(/-/g, '')
      let v = '打开眼视光检测'
      let p0 = this.comfrompath
      getcollect({ gid, p, t, r, v, p0 })
      // window.open("weixin://dl/business/?t=F3iL3ynafsl", "_href");
    },
  },

  created() {
    this.openweixin()
    this.showConent = true
  },
}
</script>
<style lang="scss">
@import '../../styles/common.scss';
@import '../../styles/flexcommon.scss';
.ocular {
  display: flex;
  flex-direction: column;
  background: #f4f5f7;
  height: 100vh;
  .title {
    font-size: 40px;
    font-family: Avenir-Heavy, Avenir;
    font-weight: 800;
    color: #1b1c21;
    margin: 140px 0 120px;
    text-align: center;
  }
  .bottomBtn {
    margin-top: 650px;
  }
  .van-cell {
    padding: 13px 14px;
  }
  .van-field__label {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: bold;
    color: #040814;
  }
  .van-field__control::placeholder {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9c9fad;
  }
}
</style>
